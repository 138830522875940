.Content{
    margin: 2rem 0;
    max-width: 900px;
    width: 90%;
}

.FormContainer{
    display: flex;
    flex-direction: column;
    background-color: var(--bg2);
    border-radius: 1rem;
    padding: 2rem;
}

.Form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.FormItemContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
}

.DisableContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.UsernameContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .25rem;
}

.Buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    margin: 1rem 0;
}

.ErrorContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ErrorTitle{
    margin-top: 2rem;
    margin-bottom: .5rem;
}

@media (max-width: 600px) {
    .UsernameContainer{
        flex-wrap: wrap;
    }
    
}