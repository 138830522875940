.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg2);
    border-radius: 2rem;
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
}

.ErrorContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ErrorTitle{
    margin-top: 2rem;
    margin-bottom: .5rem;
}

.Buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 0 0 ;
}