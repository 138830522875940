.PageContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 97%;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem 0;
    max-width: 1225px;
}

.PageHeader {
    width: 100%;
    max-width: 1300px;
}

.IndicatorsContent {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
}
.IndicatorByBuildingContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
}
.IndicatorByBuildingContentFooter{
    width: 100%;
}
.Indicators {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    /* width: 99%; */
    gap: .5rem var(--card-horizontal-gap);
    flex-wrap: wrap;
    margin-top: 1rem;
}

.GraphicContainer {
    width: calc( (4 * var(--card-width)) + (3 * 0.5rem ));
    height: var(--card-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}