.Content{
    margin: 2rem 0;
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
}

.ContentHeaderSectionItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .3rem;
}

.ContentHeaderSection {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
}

.ContentHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
}

.AddButton{
    text-wrap: nowrap;
    gap: .5rem;
    color: var(--primary-color-contrast) !important;
}

.Skeleton{
    width: 100%;
    height: 250px;
    border-radius: 2rem;
}

.GraphicContainer {
    width: 100%;
    min-height: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}

.TableContent {
    width: 100%;
    margin-top: 3rem;
}

.GraphicContent{
    width: 100%;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.GraphicArea{
    width: 100%;
    height: fit-content;
    /* height: 500px; */
    /* max-height: 1000px; */
}

.BuildingsLegendContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

.BuildingLegend {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: .5rem;
    /* width: 100%; */
    min-width: 200px;
}

.BuildingLegendColorBox{
    width: 20px;
    height: 20px;
}