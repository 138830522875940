.Header {
    width: 100%;
    height: 4.5rem;
    background-color: var(--bg1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Section {
    width: 100%;
    display: flex;
    align-items: center;
}

.Left {
    justify-content: flex-start;
}

.Center {
    justify-content: center;
    text-align: center;
}

.Right {
    justify-content: flex-end;
    gap: .3rem;
}

.Menu {
    margin-left: 2rem;
}

.AccountButton {
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0rem;
    cursor: pointer;
    border-radius: .5rem;
    padding-left: 1rem;
}

.AccountButton:hover {
    background-color: var(--bg3);
}

.AccountButton:active {
    background-color: var(--bg2);
}

.IconButton {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.IconButton:hover {
    background-color: var(--bg3);
}

.IconButton:active {
    background-color: var(--bg2);
}

/* @media (max-width: 600px) {
    .Menu{
        display: flex;
    }
} */