.PageContent { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin: 4rem;
}

.CompaniesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.CompanyItem { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 1rem;
    background-color: var(--bg2);
    border-radius: 1rem;
    width: 8rem;
    height: 8rem;
    text-align: center;
}