.GraphicComponent {
    width: 100%;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.GraphicContent{
    width: 100%;
    height: 500px;
}

.LegendContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    /* height: fit-content; */
    position: relative;
}

.Legend {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: .5rem;
    /* width: 100%; */
    min-width: 200px;
}

.LegendColorBox{
    width: 20px;
    height: 20px;
}