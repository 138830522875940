.Item{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}

.Content{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    padding: 1rem 1rem 1rem 1rem;
}

.Content:hover {
    background-color: var(--bg3);
}

.Content:active {
    background-color: var(--bg1);
}

.Title{
    flex: 1;
}

.SubItens{
    margin-left: calc(20px + .5rem);
}

a{
    text-decoration: none;
    
}