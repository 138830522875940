.Card {
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 20px;
    background-color: var(--bg2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.CardContent {
    margin: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    text-align: center;
}

.PieCardTitle {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PieContent {
    width: 120px;
    aspect-ratio: 1;
    position: relative;
}

.PieBackground{
    position: relative;
    background-color: var(--bg1);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.PieSlice{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PieMiddle{
    background-color: var(--bg2);
    width: 85%;
    height: 85%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}