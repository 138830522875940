.SkeletonContainer{
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
}
  
.SkeletonAnimation {
    width: 100%;
    min-height: 2rem;
    background-color: var(--bg2); /* Cor do bloco de carregamento */
    animation: loadingAnimation 1s infinite alternate; /* Animação de carregamento */
}

.bg1{
    background-color: var(--bg1);
}

.bg2{
    background-color: var(--bg2);
}

.bg3{
    background-color: var(--bg3);
}
  
@keyframes loadingAnimation {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
}