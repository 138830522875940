.DialogContent{
    background-color: var(--bg2);
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

}

.DialogActionButtons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
    margin-top: 1rem;
}