.Content{
    margin: 2rem 0;
    max-width: 900px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

.ContentHeaderSectionItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .3rem;
}

.ContentHeaderSection {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
}

.ContentHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
}

.Skeleton{
    width: 100%;
    height: 250px;
    border-radius: 2rem;
}

.TableContainer {
    background-color: var(--bg2);
    border-radius: 1.0rem;
    width: 100%;
}

.TableContent {
    margin: 2rem;
}

.ExportSection {
    width: 100%;
}

.ExportSectionButtons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
}

.Icons {
    color: var(--primary-color-contrast);
}