.GraphicCard {
    width: calc( (4 * var(--card-width)) + (3 * var(--card-horizontal-gap) ));
    /* height: var(--card-height); */
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    border-radius: 20px;
    background-color: var(--bg2);
}

.CardContent{
    height: calc(var(--card-height) - 1rem);
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .3rem;
    text-align: center;
    margin-top: .5rem;
}

.GraphicContainer {
    width: 100%;
    height: 100%;
}