.Card{
    width: calc((2 * var(--card-width)) + var(--card-horizontal-gap));
    height: calc(var(--card-height));
    border-radius: 20px;
    background-color: var(--bg2);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Content{
    --margin: 20px;
    width: calc(100% - (2*var(--margin)));
    height: 100%;
    margin: var(--margin);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    /* align-items: center; */
    /* justify-content: center; */
}

.Header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    max-height: 125px;
    --icon-size: 45px;
}

.Title{
    width: calc(100% - var(--icon-size));
    height: 100%;
    overflow: hidden;
}

.Icon{
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: calc(var(--icon-size)/2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.Row{
    display: flex;
    flex-direction: row;
    gap: .2rem;
    align-items: center;
}