.MenuContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    flex: 1;
}

.Header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    /* padding: 1rem 0px; */
}

.Content{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    /* margin-top: 1rem; */
    /* gap: .5rem; */
}

.Footer {
    width: 100%;
    min-height: 90px;
}

.LogoImage{
    height: 100%;
}