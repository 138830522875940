.text{
    margin: 0;
    color: var(--text-color);
}

.subtext{
    margin: 0;
    color: var(--sub-text-color);
}

.h1{
    margin: 0;
    margin-block: 0;
    font-size: 2.25rem;
    font-weight: bold;
}

.h2{
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}

.h3{
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
}

.h4{
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}

.h5{
    margin: 0;
    font-size: .75rem;
    font-weight: bold;
}

.h6{
    margin: 0;
    font-size: .625rem;
    font-weight: bold;
}

.xxs {
    font-size: .625rem;;
}

.xs {
    font-size: .75rem;
}

.sm {
    font-size: .875rem;
}

.md {
    font-size: 1rem;
}

.lg {
    font-size: 1.25rem;
}

.xl {
    font-size: 2rem;
}

.xxl {
    font-size: 2.25rem;
}


.bold {
    font-weight: 700;
}

.semiBold {
    font-weight: 600;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.clickable {
    cursor: pointer;
}