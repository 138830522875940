:root {
    --sidebar-width: 220px;
}

.SideBarComponent {
    position: relative;
    display: none;
    background-color: var(--bg2);
    min-height: 100vh;
    min-width: var(--sidebar-width);
}

.SideBarContent{
    width: 100%;
}

.Open{
    display: flex;
    width: var(--sidebar-width);
}