@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Book.otf') format('otf');
}

:root {
  /* --bg1: #ffffff; */
  --bg1: #F3F4F6;
  /* --bg1: #f4f5f6; */
  /* --bg2: #fafcfd; */
  --bg2: #ffffff;
  /* --bg2: #F3F4F6; */
  --bg3: #E5E7EB;
  --text-color: #111827;
  --sub-text-color: #6B7280;
  --primary-color: #2F59A5;
  --primary-color-contrast: #EDEFF8;

  --blue-bg1: #E0F2FE;
  --blue-bg2: #F0F9FF;
  --blue-bg3: #BAE6FD;
  --blue-text-color: #0C4A6E;
  --blue-sub-text-color: #0369A1;
  --blue-primary-color: #2F59A5;
  --blue-primary-color-contrast: #EDEFF8;

  --red-bg1: #FEE2E2;
  --red-bg2: #FEF2F2;
  --red-bg3: #FECACA;
  --red-text-color: #400F0F;
  --red-sub-text-color: #991B1B;
  /* --red-primary-color: #DC2626; */
  --red-primary-color: #C00300;
  --red-primary-color-contrast: #FEF2F2;

  --yellow-bg1: #FEF9C3;
  --yellow-bg2: #FEFCE8;
  --yellow-bg3: #FEF08A;
  --yellow-text-color: #713F12;
  --yellow-sub-text-color: #A16207;
  /* --yellow-primary-color: #CA8A04; */
  --yellow-primary-color: #F7D966;
  --yellow-primary-color-contrast: #713F12;

  --green-bg1: #DCFCE7;
  --green-bg2: #F0FDF4;
  --green-bg3: #BBF7D0;
  --green-text-color: #14532D;
  --green-sub-text-color: #15803D;
  /* --green-primary-color: #16A34A; */
  --green-primary-color: #548235;
  --green-primary-color-contrast: #F0FDF4;

  --orange-bg1: #FFE8D6; /* Tom claro para fundo */
  --orange-bg2: #FFF7ED; /* Tom ainda mais claro para áreas amplas */
  --orange-bg3: #FFD8A8; /* Tom médio para contrastar */
  --orange-text-color: #7C2D12; /* Cor do texto principal */
  --orange-sub-text-color: #D97706; /* Cor do texto secundário */
  --orange-primary-color: #EA580C; /* Cor primária */
  --orange-primary-color-contrast: #FFF7ED; /* Contraste com a cor primária */

  --card-width: 150px;
  --card-height: 200px;

  --card-horizontal-gap: .2rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg1: #111827;
    --bg2: #1F2937;
    --bg3: #374151;
    --text-color: #F9FAFB;
    --sub-text-color: #9CA3AF;
    --primary-color: #3565BF;
    --primary-color-contrast: #E5EBFF;

    --blue-bg1: #0C4A6E;
    --blue-bg2: #075985;
    --blue-bg3: #0369A1;
    --blue-text-color: #F0F9FF;
    --blue-sub-text-color: #BAE6FD;
    --blue-primary-color: #3565BF;
    --blue-primary-color-contrast: #E5EBFF;

    --red-bg1: #400F0F;
    --red-bg2: #7F1D1D;
    --red-bg3: #991B1B;
    --red-text-color: #FEF2F2;
    --red-sub-text-color: #FECACA;
    --red-primary-color: #EF4444;
    --red-primary-color-contrast: #FEF2F2;

    --yellow-bg1: #713F12;
    --yellow-bg2: #854D0E;
    --yellow-bg3: #A16207;
    --yellow-text-color: #FEFCE8;
    --yellow-sub-text-color: #FEF08A;
    --yellow-primary-color: #EAB308;
    --yellow-primary-color-contrast: #FEFCE8;

    --green-bg1: #14532D;
    --green-bg2: #166534;
    --green-bg3: #15803D;
    --green-text-color: #F0FDF4;
    --green-sub-text-color: #BBF7D0;
    --green-primary-color: #22C55E;
    --green-primary-color-contrast: #F0FDF4;

    --orange-bg1: #7C2D12; /* Fundo escuro */
    --orange-bg2: #9A3412; /* Fundo intermediário */
    --orange-bg3: #C2410C; /* Fundo mais vibrante */
    --orange-text-color: #FFF7ED; /* Cor do texto principal */
    --orange-sub-text-color: #FFD8A8; /* Cor do texto secundário */
    --orange-primary-color: #F97316; /* Cor primária vibrante */
    --orange-primary-color-contrast: #FFF7ED; /* Contraste para a cor primária */
  }
}

a {
  all: initial;
  cursor: pointer;
  color: var(--text-color);
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  /* font-family: 'Avenir', sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: var(--bg1);
}