.Icon{
    color: var(--text-color);
}

.PrimaryContrast {
    color: var(--primary-color-contrast);
}

.Pressable {
    cursor: pointer;
}