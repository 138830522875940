.Page{ 
    display: flex;
    flex-direction: row;
    background-color: var(--bg1);
    min-height: 100vh;
    min-width: 100%;
    max-width: 100%;
    justify-content: flex-start;
}

.Content {
    display: flex;
    flex-direction: column;
    background-color: var(--bg1);
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.Open {
    max-width: calc(100% - var(--sidebar-width));
}