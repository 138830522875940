.Page {
    align-items: center;
    justify-content: center;
}

.Box {
    background-color: var(--bg2);
    color: var(--text-color);
    border-radius: 2rem;
    margin: 1rem 2rem;
    width: 90%;
    max-width: 70rem;

}

.Content {
    margin: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ContentHeader {
    width: 100%;
}

.LogoImage{
    width: 100%;
    max-width: 7rem;
}

.ContentBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
}

.ContentBodySection{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ContentContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .3rem;
}

.ContentBodySection:nth-child(2){
    margin-top: 1rem;
}

.ForgotPasswordLink{
    color: var(--primary-color) !important;
    cursor: pointer;
    padding: .2rem .4rem;
}

.ForgotPasswordLink:hover{
    background-color: var(--primary-color-contrast);
}

.Subtitle{
    max-width: 30rem;
}

#EnviarButton{
    width: fit-content;
    align-self: flex-end;
}

.ErrorMessage {
    color: var(--red-primary-color) !important;
}

@media (prefers-color-scheme: dark) {
    .ForgotPasswordLink{
        color: var(--text-color) !important;
    }
    
    .ForgotPasswordLink:hover{
        background-color: var(--bg3);
    }
}

@media (max-width: 600px) {
    .ContentBody {
        flex-direction: column;
    }
}