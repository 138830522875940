.Page{
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: center; */
}
.PageContent{
    /* min-height: 100vh; */
    max-width: 50rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
}

.LogoImage{
    width: 8rem;
    margin-bottom: 1rem;
}

.GoHomeButton{
    margin-top: 1rem;
}