.ContentHeaderSectionItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .3rem;
    width: 100% ;
    max-width: inherit;
}

.ContentHeaderSection {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
}

.ContentHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
}

.Skeleton{
    width: 100%;
    height: 250px;
    border-radius: 2rem;
}